<template>
  <el-card class="search-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>样例</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="forms" ref="forms" label-width="70px" class="search-form" size="small">
      <el-form-item label="关键词" prop="keyword">
        <el-col :span="12">
          <el-input v-model="forms.keyword"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="submit('forms')">搜索</el-button>
        <el-button @click="reset('forms')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="body-card" :body-style="{ 'padding-top': '0','border-bottom':'none' }">
    <div class="toolbar">
      <el-button type="primary" icon="el-icon-plus" size="small">新增</el-button>
      <!-- <el-button type="primary" icon="el-icon-edit" size="small">编辑</el-button> -->
      <el-button type="danger" icon="el-icon-delete" size="small">删除</el-button>
      <el-button type="primary" plain icon="el-icon-share" size="small">分享</el-button>
    </div>
    <el-table class="table" border :data="tableData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }" size="small">
      <el-table-column prop="date" label="日期" sortable width="180">
      </el-table-column>
      <el-table-column prop="name" label="姓名" sortable width="180">
      </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template #default="scope">
          <el-button @click="handleClick(scope.row)" link size="small">查看</el-button>
          <el-button link size="small">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" :total="1000" class="pagination">
    </el-pagination>
  </el-card>
</template>
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      forms: {
        keyword: "",
      },
      tableData: [
        { date: "2016-05-02", name: "王小虎1", address: "上海市普陀区金沙江路 1518 弄", },
        { date: "2016-05-04", name: "王小虎2", address: "上海市普陀区金沙江路 1517 弄", },
        { date: "2016-05-01", name: "王小虎3", address: "上海市普陀区金沙江路 1519 弄", },
        { date: "2016-05-03", name: "王小虎4", address: "上海市普陀区金沙江路 1516 弄", },
      ],
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.$refs[formName]);
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset(formName) {
      this.$refs[formName].resetFields();
    },

    handleClick(row) {
      console.log(row);
    }
  },
};
</script>